import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import IndustryCasestudyTabs from "../../molecules/Industry/IndustryCasestudyTabs";
// import SlantedHeader from "../../molecules/aboutus/AboutUsHeader";
import IndustrySlider from "../../molecules/Industry/IndustrySlider";
// import ContactForm from "../../molecules/ContactForm";
import ImageBanner from "../../components/atoms/banner/imageBanner";
// import Bounce from "react-reveal/Bounce";

import { useStaticQuery, graphql } from "gatsby";
import IndustryOurClients from "../../molecules/Industry/IndustryOurClients";
import newBannerImg from "../../images/industryassets/banners/aerospace_bg.png";
//image
// import bgImg from "../../images/industryassets/aerospace.png";
// import bgImgIn from "../../images/industryassets/aerospace/AerospaceIn.png";
// import bgImgEn from "../../images/industryassets/aerospace/AerospaceEn.png";
// import bgImgJa from "../../images/industryassets/aerospace/AerospaceJa.png";

// import mobileImg from "../../images/industryassets/banners/AerospaceMobileImg.png";
// import tabletImg from "../../images/industryassets/banners/Aerospace.png";
// import IndustryNewHeader from "../../molecules/Industry/v2.1/IndustryNewHeader";
import IndustryFullImageHeader from "../../molecules/Industry/v2.1/IndustryFullImageHeader";

const Aerospace = () => {
  const data = useStaticQuery(graphql`
    query GetAerospaceData {
      gcms {
        industryPages(where: { industrPageType: AEROSPACE }) {
          industrPageType
          headerParagraph
          solutionsPageSlides {
            listItems
            slideHeading
            paragraph
            slideImage {
              url
            }
            url
          }
          insightPages {
            image {
              url
            }
            slug
            title
            typeOfInsight
            writtenBy
            shortDescription
          }
          linkedInArticlesIframeUrl
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        pageName={"Aerospace Industry"}
        canonicalLink={"https://www.goken-global.com/industry/aerospace/"}
        title="Aerospace Industry: Aerospace Solutions by Goken"
        description={
          "Join the aerospace revolution with Goken. Innovative solutions for design, engineering, and manufacturing. Click to soar with our expertise!"
        }
        // meta="aerospace, aerospace industry, aerospace engineering, aeronautics, aerospace engineer"
        facebookTitle={
          "Goken | Aerospace Engineering Company - Design | Validation | Training"
        }
        facebookDescription={
          "Goken as an Aerospace Engineering Company offers engineering support to problems faced by aircraft manufacturers today. Goken support thus involves - engineering design, training, automation, validation to help the Aerospace industry grow."
        }
        keywords={
          "aerospace industry, design for aerospace components, training for aerospace industry, engineering solutions for aerospace, automation in aerospace design"
        }
      ></SEO>
      {data.gcms && (
        <>
          {/* <SlantedHeader
            header="Aerospace"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get in touch"
            btnLink="/contactus/"
            background={"aerospace"}
            isColorPrimary={true}
            bgImageClass="bg-image-aerospace"
            currentPage="industry aerospace"
            // bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            bgImgMobile={mobileImg}
            bgImgTablet={tabletImg}
            // children={
            //   <IndustryOurClients isAerospace={true}></IndustryOurClients>
            // }
          ></SlantedHeader> */}
          {/* <IndustryNewHeader
            header="AEROSPACE"
            para={data.gcms.industryPages[0].headerParagraph}
            btnText="Get in touch"
            btnLink="/contactus/"
            background={"aerospace"}
            isColorPrimary={true}
            bgImageClass="bg-image-aerospace"
            currentPage="industry aerospace"
            // bgImg={bgImg}
            bgImgIn={bgImgIn}
            bgImgEn={bgImgEn}
            bgImgJa={bgImgJa}
            bgImgMobile={mobileImg}
            bgImgTablet={tabletImg}
          /> */}
          <IndustryFullImageHeader
            bgImgTablet={newBannerImg}
            header="AEROSPACE"
            para={data.gcms.industryPages[0].headerParagraph}
          />

          <IndustryOurClients isAerospace={true} />

          <IndustrySlider
            hideUrl={false}
            SliderItems={data.gcms.industryPages[0].solutionsPageSlides}
            heading="Aerospace"
          ></IndustrySlider>
          <IndustryCasestudyTabs
            heading={"OUR WORK IN THE AEROSPACE INDUSTRY"}
            insights={data.gcms.industryPages[0].insightPages}
            linkedinArticlesUrl={
              data.gcms.industryPages[0].linkedInArticlesIframeUrl
            }
          ></IndustryCasestudyTabs>
        </>
      )}
      {/* <ContactForm showForm={true} typeOfForm="General Inquiry"></ContactForm> */}
      <ImageBanner
        heading="Learn more about our services in the Aerospace industry"
        btnText="Get in Touch"
        link="/contactus/"
      ></ImageBanner>
    </Layout>
  );
};

export default Aerospace;
